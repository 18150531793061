* {
    box-sizing: border-box;
  }
  .testing{
    z-index: 9999;
    background: #000;
    color: #fff;
    position: fixed;

  }
  body.modal-opened {
    overflow: hidden !important;
}
  #testing-modal{
      padding-top: 0px;
  }
 
  #testing-modal .formContainer{
     padding: 30px 0px 60px 0px; 
    
  }
  #testing-modal .thankyou-pop{
    padding: 0px 0px 0px 0px; 
  }
    #testing-modal .popup-logo{
        max-width: 36%;
        margin: 0 auto;
    }
div#testing-modal {
    padding-right: 0px !important;
    z-index: 999999 !important;
}
#testing-modal input#email{
    font-size: 20px;
}
#testing-modal .main-content span{
    font-weight: 700;
}
#testing-modal input#email{
    font-size: 20px;
    font-family: 'Lato';
}
#testing-modal button.btn.form-control.popup--btn{
    font-size: 25px !important;
    font-family: 'Lato' !important;  
}
#testing-modal button.btn.form-control.popup--btn:hover{
    font-size: 25px !important;
    font-family: 'Lato' !important;  
    background: #9e7948 !important;
}

  @media screen and (max-width: 767px)
  {
      /* .body{
         height: 140vh;
      } */
    /*28 October Muskaan CSS*/

    #testing-modal input#email{
        font-size: 18px;
        font-family: 'Lato';
    }
    #testing-modal .close-button svg {
        margin: 8px 12px 8px 8px;
    }
    #testing-modal .popup-logo {
        max-width: 45%;
        margin: 0 auto;
    }
    #testing-modal .main-heading {
        font-size: 34px !important;
        /* word-break: break-all; */
        font-weight: 700;
    }
    #testing-modal .main-content{
        font-size: 20px!important;
        line-height: 30px!important;
        /* padding-left: 45px;
        padding-right: 45px; */
        font-family: 'LATO';
        font-weight: 700;
    }
    #testing-modal .formContainer svg.svg-inline--fa.fa-times.fa-w-11 {
        padding: 10px 5px 7px 5px;
        border: 0.1px solid #343332;
            /* color: white; */
        margin: -10px 23px 18px 18px;
        font-size: 35px;
        background: #333332;
        border-radius: 100%;
       
    }
    #testing-modal .main-content span{
        font-weight: 700!important;
        font-family: 'LATO';
    }
    #testing-modal .formContainer{
        padding: 30px 0px 80px 0px;
    }
    #testing-modal .thankyou-pop{
        padding: 0px 0px 0px 0px;
        position: absolute;
    top: 80px;
    }
    #testing-modal .formContainer{
        max-width: 100% !important;
        width: 100% !important;
        height: 100% !important;
        font-weight: 400;

    }
    #testing-modal .thankyou-pop{
        max-width: 100% !important;
        width: 100% !important;
        /* height: 100%; */
        font-weight: 400;
    }
    #testing-modal .main-heading{
        font-size: 31px !important;
        /* word-break: break-all; */
        padding: 0px 5px 10px 5px;
        font-family: 'LATO';
        font-weight: 700;
        
    }
    #testing-modal button.btn.form-control.popup--btn:hover{
        font-size: 25px !important;
        font-family: 'Lato' !important;  
        background: #9e7948 !important;
    }
    #testing-modal button.btn.form-control.popup--btn{
        font-size: 22px !important;
        font-family: 'Lato' !important;
        
    }
    #testing-modal .col-md-12.col-12.col-lg-12 {
        padding: 10px 0px 30px 0px;
    }
    /* .formContainer{
        height: 140vh;
    } */



    .main-content{
        font-size: 16px !important;
    line-height: 23px !important;
    font-weight: 400 !important;
    /* word-break: break-all; */
    }
    input.form-control.popup-fields{
        padding-top: 16px;
        padding-bottom: 16px;
    }
    button.btn.form-control.popup--btn{
        padding: 13px 0px !important;
    }

    .newpop{
        
        max-width: 100%;
        width: 100%;
        
    }
    .content-12 p{
        font-size: 21px !important;
    }
    .content-12 p span {
        font-size: 21px!important;
    }
    form.ftr-frm button.btn.form-control,
    input#email{
        width:100% !important;
    }
    input.form-control.popup-fields{
        padding: 10px 20px !important;
    }
    .written-content{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    /* new css 26-10 */
    /* .loginPopup {
        display: flex;
        justify-content: center;
        align-items: center;
        height: unset !important;
       
    } */
    .formPopup{
        /* position: unset !important; */
        /* top: 2rem !important; */
        width: 100%;
        max-width: 550px;
        position: absolute;
        top: 87.5px;
        display: block;
        left: 0px;
        margin-bottom: 0px;
        transition: top 0.2s ease 0s, left 0.2s ease 0s, margin-bottom 0.2s ease 0s;
        z-index: 99999;
    }
  
  }
  form.thankyou-pop {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    width: 50%;
    margin-top: 50px;
  }
  .openBtn {
    display: flex;
    justify-content: left;
  }
  .openButton {
    border: none;
    border-radius: 5px;
    background-color: #1c87c9;
    color: white;
    padding: 14px 20px;
    cursor: pointer;
    position: fixed;
    font-size: 30px;
  }
  /* .loginPopup {
    position: relative;
    text-align: center;
    width: 100%;
  } */
  .formPopup {
    display: none;
    position: fixed;
    left: 0px;
    top: 0;
    right:0;
    bottom:0px;
    z-index: 9;
    margin:0 auto;
  }
  .formContainer {
    max-width: 550px;
    padding:10px 0 60px 0; 
    background-color: #343332;
    margin:0 auto;
    /* height:100%; */
  }
  .thankyou-pop {
    max-width: 550px;
    padding:10px 0 60px 0; 
    background-color: #343332;
    margin:0 auto;
    /* height:100%; */
  }
  div#popupForm .popup-logo {
    max-width: 36%;
    margin: 0 auto;
}
div#popupForm .popup-logo img{
    width:100%;
    height:100%;
    object-fit:cover;
    object-position: center;
}
  .main-heading{
    font-size: 34px !important;
    font-family: 'Cabin', sans-serif;
    font-weight: 600;
    color: #fff;
    margin-block-start: 0;
    margin-block-end: 0;
    text-align: center;
    padding-bottom: 4px;

  }
  h2.main-heading span {
    color: rgb(179, 177, 117);
}
.written-content {
    padding-right: 20px;
    padding-left: 20px;
}
.main-content {
    font-size: 24px !important;
    font-family: Lato;
    font-weight: 700;
    /* font-family: 'Lato', sans-serif;
    font-weight: 600 !important;  */
    color: rgb(255, 255, 255);
    margin: 0;
    text-align: center;
    padding-bottom: 16px;
    word-spacing: 1px;
    /* line-height: 30px; */
    line-height: 1.3em !important;
}
.main-content span{
    color: rgb(179, 177, 117);
    font-weight: 700;
}
input.form-control.popup-fields{
    height:unset;
    color: #291B1A;
    font-size: 16px;
    font-weight: 400;
    font-family: Cabin;
    background: #ffffff;
    border-style: none;
    border-color: #b3b175;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    display: block;
    padding-top: 18px;
    padding-right: 30px;
    padding-bottom: 18px;
    padding-left: 30px;
    max-width: 500px;
    width: 100%;
    margin:0 auto;
}
button.btn.form-control.popup--btn {
    background: #b3b175 !important;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    border-bottom-width: 0px !important;
    border-color: rgba(0,0,0,.35) !important;
    border-left-width: 0px !important;
    border-right-width: 0px !important;
    border-style: none !important;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
    border-top-width: 0px !important;
    box-shadow: 0px 0px 0px 0px rgb(228 97 136 / 0%) !important;
    color: #ffffff !important;
    font-family: Cabin !important;
    font-size: 21px !important;
    font-weight: 700 !important;
    line-height: 1 !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
    max-width: 100% !important;
    padding-bottom: 20px ;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 20px ;
    text-transform: none !important;
    width: 100% !important;
    max-width:500px;
    height:unset;
    margin-top:24px !important;
}

/* button.btn:hover{
    background: #9e7948 !important;
    transition: .6s ease;
} */
.close-button i {
    font-size: 18px;
    position: absolute;
    right: 4%;
    top: 7%;
    color: #fff;
}
.close-button i:hover{
    color: #000;
}

#newPopup{
    /* visibility:hidden; */
    display: none;
}
/* .newpop{
    display: block;
} */

.newpop {
   
    position: fixed;
    left: 50%;
    top: 11%;
    transform: translate(-50%, 1%);
  
    z-index: 9;
  }
  .newpop {
    max-width: 550px;
    padding: 0px;
    background-color: #343332;
    z-index: 9999;
    
    
  }

  .popimg img{
      width: 45%;
  }
  .popimg {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.congratulation h2 {
    font-size: 42px;
    font-family: 'Cabin', sans-serif;
    font-weight: 600;
    color: rgb(179, 177, 117);
    text-align: center;
    margin: 0;
}

.content-12 p {
    font-size: 27px !important;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 0px 41px;
}

.content-12 p span{
font-size: 27px !important;
font-family: 'Lato', sans-serif;
font-weight: 400;
color: rgb(179, 177, 117);
}

#popupForm{
    transition: 0.6s ease;
}
/* popup-design */
.loginPopup div#popupForm {
    z-index: 99999;
    height: 100%;
}
div#popupForm form.formContainer .close-button {
    display: block !important;
    text-align: right !important;
    width: 100%;
    color: #fff;
    padding: 10px;
    cursor: pointer;
}
div#popupForm form.thankyou-pop .close-button {
    display: block !important;
    text-align: right !important;
    width: 100%;
    color: #fff;
    padding: 10px;
    cursor: pointer;
}

.listen-icons-wrap li {
    display: inline-block;
}
.listen-icons-wrap ul {
    padding-left:0;
    margin-bottom:0px;
}
img.image-icon {
    width: 32px;
    height: 32px;
    margin-right: 14px;
}
.container.listen-icons-wrap {
    margin-top: 34px;
}
form.ftr-frm {
    margin: 0 auto;
    text-align: center;
}
ul.sharing-icon li {
    margin-right:10px;
}
ul.sharing-icon button.react-share__ShareButton {
    width: 30px;
    height: 30px;
    background-color: #7c7b76!important;
    border-radius: 50%;
}
ul.sharing-icon  path {
    color: #fff;
}
.col-lg-6.col-md-6.cool-sm-12.listen-icons-wrap.text-right p.listen-wrap-sec.mb-0 {
    font-weight: 700;
    font-size: 22px;
}

.svg-inline--fa.fa-w-11 {
    width: 0.6875em;
    color: #fff;
    float: right;
    margin-right: 20px;
}

.congratulation {
    padding-top: 25px;

    
}
.close-button svg {
    margin: 8px;
}