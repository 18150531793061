.register-page {
  width: 100%;
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.error-message {
  color: red;
  font-size: 14px;
  margin: 0 5px;
}
